<template>
  <div class="prepaid">
    <div class="prepaid__content" v-loading="loading">
      <div class="prepaid__title">
       {{ $t('ref_values.standard') }} (%) 
      </div>
      <div class="prepaid__steps">
        <div class="prepaid__step">
          <div class="prepaid__step-title">
            1. {{ $t('ref_values.new_value') }} 
          </div>
          <div class="prepaid__step-content">
            <MonthPicker
              ref="monthPicker"
              :title="$t('ref_values.date')"
              class="reference-month-picker"
              @changeDates="changeDate"
            />
            <div class="reference-platforms">
              <div
                v-for="(platforms, key) in form"
                :key="key"
                class="reference-platforms__item"
              >
                <div class="platform__logo">
                  <img v-if="key === 'yandex'" class="small" src="@/assets/icons/icon-yandex.svg" alt="platform-logo">
                  <img v-else-if="key === 'vk.com'" class="small" src="@/assets/icons/icon-vk.svg" alt="platform-logo">
                  <img v-else-if="key === 'facebook'" class="small" src="@/assets/icons/icon-facebook-new.svg" alt="platform-logo">
                  <img v-else-if="key === 'vkAds'" class="small" src="@/assets/icons/icon-vkreklama.svg" alt="platform-logo">
                  <img v-else-if="key === 'myTarget'" src="@/assets/icons/icon-mytarget-new.svg" alt="platform-logo">
                  <img v-else-if="key === 'google'" src="@/assets/icons/icon-google.svg" alt="platform-logo">
                  <img v-else-if="key === 'telegram'" src="@/assets/icons/icon-telegram.svg" alt="platform-logo">
                  {{ key }}
                </div>
                <div
                  class="form-input"
                  :class="[{'form-input--full' : form[key]}]"
                >
                  <input v-model="form[key]" type="text">
                  <span class="form-input__label">{{$t('ref_values.value')}}, %</span>
                </div>
              </div>
            </div>
            <ButtonElement
              :text="$t('button_props.append')"
              class="add-reference-platform"
              @click-button="addReference('save')"
            />
          </div>
        </div>

        <div v-if="history && history.length" class="prepaid__step">
          <div class="prepaid__step-title">
            2. {{$t('ref_values.history')}}
          </div>
          <el-table
            :data="history"
            style="width: 100%"
            class="reference-values-table"
          >
            <el-table-column
              width="200"
              :label="$t('ref_values.month')"
            >  
              <template slot-scope="scope">
                {{ fixDate(scope.row.month_date, true, true) }}
              </template>
            </el-table-column>

            <el-table-column>
              <template slot="header">
                <div class="platform__logo">
                  <img class="small" src="@/assets/icons/icon-mytarget-new.svg" alt="platform-logo">
                  myTarget
                </div>
              </template>
              <template slot-scope="scope">
                <div
                  v-if="editableRow && editableRow === scope.row.month_date"
                  class="form-input"
                  :class="[{'form-input--full' : editValues.mytarget}]"
                >
                  <input v-model="editValues.mytarget" type="text">
                  <span class="form-input__label">{{$t('ref_values.value')}}, %</span>
                </div>
                <span v-else>{{ foundHistoryValue(scope.row.items, 'mytarget') }}</span>
              </template>
            </el-table-column>
            
            <el-table-column>
              <template slot="header">
                <div class="platform__logo">
                  <img class="small" src="@/assets/icons/icon-vk.svg" alt="platform-logo">
                  vk.com
                </div>
              </template>
              <template slot-scope="scope">
                <div
                  v-if="editableRow && editableRow === scope.row.month_date"
                  class="form-input"
                  :class="[{'form-input--full' : editValues.vkontakte}]"
                >
                  <input v-model="editValues.vkontakte" type="text">
                  <span class="form-input__label">{{$t('ref_values.value')}}, %</span>
                </div>
                <span v-else>{{ foundHistoryValue(scope.row.items, 'vkontakte') }}</span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <div class="platform__logo">
                  <img class="small" src="@/assets/icons/icon-yandex.svg" alt="platform-logo">
                  yandex
                </div>
              </template>
              <template slot-scope="scope">
                <div
                  v-if="editableRow && editableRow === scope.row.month_date"
                  class="form-input"
                  :class="[{'form-input--full' : editValues.yandex}]"
                >
                  <input v-model="editValues.yandex" type="text">
                  <span class="form-input__label">{{$t('ref_values.value')}}, %</span>
                </div>
                <span v-else>{{ foundHistoryValue(scope.row.items, 'yandex') }}</span>
              </template>
            </el-table-column>

            <el-table-column>
              <template slot="header">
                <div class="platform__logo">
                  <img class="small" src="@/assets/icons/icon-vkreklama.svg" alt="platform-logo">
                  vkAds
                </div>
              </template>
              <template slot-scope="scope">
                <div
                  v-if="editableRow && editableRow === scope.row.month_date"
                  class="form-input"
                  :class="[{'form-input--full' : editValues.vkads}]"
                >
                  <input v-model="editValues.vkads" type="text">
                  <span class="form-input__label">{{$t('ref_values.value')}}, %</span>
                </div>
                <span v-else>{{ foundHistoryValue(scope.row.items, 'vkads') }}</span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <div class="platform__logo">
                  <img class="small" src="@/assets/icons/icon-google.svg" alt="platform-logo">
                  google
                </div>
              </template>
              <template slot-scope="scope">
                <div
                  v-if="editableRow && editableRow === scope.row.month_date"
                  class="form-input"
                  :class="[{'form-input--full' : editValues.google}]"
                >
                  <input v-model="editValues.google" type="text">
                  <span class="form-input__label">{{$t('ref_values.value')}}, %</span>
                </div>
                <span v-else>{{ foundHistoryValue(scope.row.items, 'google') }}</span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <div class="platform__logo">
                  <img class="small" src="@/assets/icons/icon-telegram.svg" alt="platform-logo">
                  telegram
                </div>
              </template>
              <template slot-scope="scope">
                <div
                  v-if="editableRow && editableRow === scope.row.month_date"
                  class="form-input"
                  :class="[{'form-input--full' : editValues.telegram}]"
                >
                  <input v-model="editValues.telegram" type="text">
                  <span class="form-input__label">{{$t('ref_values.value')}}, %</span>
                </div>
                <span v-else>{{ foundHistoryValue(scope.row.items, 'telegram') }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column>
              <template slot="header">
                <div class="platform__logo">
                  <img class="small" src="@/assets/icons/icon-meta.svg" alt="platform-logo">
                  facebook
                </div>
              </template>
              <template slot-scope="scope">
                {{ foundHistoryValue(scope.row.items, 'facebook') }}
              </template>
            </el-table-column> -->
            <el-table-column
              width="50"
            >
              <template slot-scope="scope">
                <img v-if="!editableRow" :key="scope.row.month_date" class="edit-reference" src="@/assets/icons/icon-edit-medium.svg" alt="edit" @click="editData(scope.row)">
                <img v-if="editableRow && editableRow === scope.row.month_date" :key="scope.row.month_date"  class="edit-reference edit-reference--save" src="@/assets/icons/icon-save-medium.svg" alt="edit" @click="saveEditData(scope.row)">
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import MonthPicker from "@/components/sort/MonthPicker";
import formValidate from "@/mixin/formValidate";
import numberWithSpaces from '@/mixin/numberWithSpaces'

export default {
  name: 'ReferenceValuesDialog',
  components: {
    ButtonElement,
    MonthPicker,
  },
  mixins: [formValidate, numberWithSpaces],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      loading: true,
      editableRow: null,
      date: null,
      form: {
        myTarget: '',
        yandex: '',
        'vk.com': '',
        vkAds: '',
        google: '',
        /* facebook: '', */
        telegram: ''
      },
      editValues: {
        mytarget: '',
        yandex: '',
        vkontakte: '',
        vkads: '',
        google: '',
        telegram: ''
      },
      monthNames: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
      monthNamesUSA: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
    }
  },
  computed: {
    history() {
      return this.$store.state.Reports.referenceValues
    },
  },
  created() {
    this.loading = false
  },
  mounted() {
    const token = localStorage.getItem('token')
    this.$store.dispatch('Reports/getReferenceValues', token)
  },
  methods: {
    changeDate(val) {
      this.date = this.fixDate(val, false, false)
    },
    addReference(type) {
      const token = localStorage.getItem('token')
      let data = null
      if (type === 'save') {
        if (!this.form.myTarget || !this.form.yandex || !this.form['vk.com'] || !this.form.telegram) {
          this.$notify({
            message: this.$t('ref_values.error'),
            type: 'error'
          });
          return false
        }
        data = {
          date:this.date,
          values: [
            {
              service: 'mytarget',
              percent: +this.form.myTarget
            },
            {
              service: 'yandex',
              percent: +this.form.yandex
            },
            {
              service: 'vkontakte',
              percent: +this.form['vk.com']
            },
            {
              service: 'vkads',
              percent: +this.form.vkAds
            },
            {
              service: 'google',
              percent: +this.form.google
            },
/*             {
              service: 'facebook',
              percent: +this.form.facebook
            }, */

            {
              service: 'telegram',
              percent: +this.form.telegram
            }
          ]
        }
      } else {
        if (!this.editValues.mytarget || !this.editValues.yandex || !this.editValues.vkontakte || !this.editValues.vkads || !this.editValues.google || !this.editValues.telegram) {
          this.$notify({
            message: this.$t('ref_values.error_edit'),
            type: 'error'
          });
          return false
        }
        data = {
          date:this.date,
          values: [
            {
              service: 'mytarget',
              percent: +this.editValues.mytarget
            },
            {
              service: 'yandex',
              percent: +this.editValues.yandex
            },
            {
              service: 'vkontakte',
              percent: +this.editValues.vkontakte
            },
            {
              service: 'vkads',
              percent: +this.editValues.vkads
            },
            {
              service: 'google',
              percent: +this.editValues.google
            },
            {
              service: 'telegram',
              percent: +this.editValues.telegram
            },
          ]
        }
      }
      if (!this.date) {
       this.$notify({
          message: this.$t('ref_values.choose_month'),
          type: 'error'
        });
        return false
      }
      this.loading = true
      this.editableRow = null
      this.date = null
      for (const key in this.form) {
        this.form[key] = ''
      }
      this.$refs.monthPicker.date = ''
      this.$store.dispatch('Reports/addReferenceValues', {data, token})
        .then(() => {
          this.loading = false
          this.$store.dispatch('Reports/getReferenceValues', token)
          this.$notify({
            message: this.$t('ref_values.data_saved'),
            type: 'success'
          });
        })
        .catch(() => {
          this.loading = false
        })
    },
    editData(val) {
      this.editableRow = val.month_date
      this.editValues.mytarget = this.foundHistoryValue(val.items, 'mytarget')
      this.editValues.yandex = this.foundHistoryValue(val.items, 'yandex')
      this.editValues.vkontakte = this.foundHistoryValue(val.items, 'vkontakte')
      this.editValues.vkads = this.foundHistoryValue(val.items, 'vkads')
      this.editValues.google = this.foundHistoryValue(val.items, 'google')
    },
    saveEditData(val) {
      this.date = this.fixDate(val.month_date, true, false)
      this.addReference()
    },
    fixDate (date, toLocal, fullMonth) {
      let fixDate

      if (toLocal) {
        fixDate = new Date(date)
      } else {
        fixDate = date
      }
      if (fixDate && !fullMonth) {
        let str = ''
        const day = fixDate.getDate()
        const month = fixDate.getMonth()
        const year = fixDate.getFullYear()
        str = `${day < 10 ? '0' : ''}${day}-${month + 1 < 10 ? '0' : ''}${month + 1}-${year}`
        return str
      }
      if (fixDate && fullMonth) {
        let str = ''
        const month = fixDate.getMonth()
        const year = fixDate.getFullYear()
        if(this.locale ==='RU') str = `${this.monthNames[month]} ${year}`
        if(this.locale ==='USA') str = `${this.monthNamesUSA[month]} ${year}`
        return str
      }
    },

    foundHistoryValue(val, name) {
      const x = val.find(el => el.service === name)
       return x ? x.percent : null
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.prepaid {
  background: #fff;
  color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.7rem;
    margin-bottom: 6.5rem;
    color: #000;
  }

  &__content {
    padding: 7rem 6rem;
  }

  &__final {
    width: 100%;
    background: #EFF1F4;
    padding: 3.3rem 6rem;
    display: flex;

    .button {
      width: 27rem;
      height: 4.5rem;
    }
  }

  &__step {
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.4rem;
      color: #333333;
      margin-bottom: 2rem;
    }

    &-content {
      .form-input {
        width: 100%;
        max-width: 35rem;

        @include below($sm) {
          max-width: 100%;
        }
      }
    }

    &-error {
      color: $redLight;
      position: absolute;
      left: 6rem;
    }
  }
}

.reference-platforms {
  display: flex;
  flex-wrap: wrap;
  margin-top: .8rem;

  &__item {
    width: 50%;
    display: flex;
    align-items: center;
    margin-top: 1.6rem;
    padding-right: 3rem;

    .form-input {
      margin-top: 0;
    }
  }
}

.platform__logo {
  flex-shrink: 0;
  width: 12rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  
  img {
    max-width: 100%;
    flex-shrink: 0;
    margin-right: .7rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.edit-reference {
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  padding-right: 1rem;

  &--save {
    opacity: 1;
    visibility: visible;
  }
}

.reference-values-table {

  .form-input {
    width: 80%;
  }
  .el-table__row:hover {
    .edit-reference {
      opacity: 1;
      visibility: visible;
    }
  }
}
.add-reference-platform {
  height: 4.5rem;
  width: 26rem;
  margin-top: 2rem;
}

.reference-month-picker {
  width: 100%;
  max-width: 35rem;
}

@include below(993px) {
  .prepaid__title{
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 30px;
  }

  .prepaid__step{
    margin-bottom: 30px;
  }

  .prepaid__content{
    padding: 15px;
  }
}
</style>
